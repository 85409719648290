/**
 * Custom exception for when an action is not allowed
 */
export class ActionNotAllowed extends Error {
  constructor(message?: string) {
    if (message) {
      super(message);
    } else {
      super('Action not allowed');
    }
  }
}
