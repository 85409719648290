import { RealTimeMetricEntity } from '@/domain/entities/real-time';
import { IRealTimeRepository } from '@/domain/ports/real-time/repositories';

export class GetTotalPickupCallMetric {
  constructor(private readonly realTimeMetricRepository: IRealTimeRepository) {}

  async run(timePeriod: string): Promise<RealTimeMetricEntity> {
    return this.realTimeMetricRepository.getRealTimeMetric(timePeriod);
  }
}
