import { gql } from '@apollo/client';

export const REAL_TIME_METRIC = gql`
  query RealTimeReport($timePeriod: String) {
    RealTimeMetric_Cr45(timePeriod: $timePeriod) {
      timePeriod
      overall
      topBusinessUnits {
        businessUnitId
        businessUnitName
        cr45
      }
    }
    RealTimeMetric_TotalPickUpCall {
      totalPickUpCall
      topUsers {
        userId
        metricName
        userName
        totalCalls
        lastUpdate
      }
    }
  }
`;
