import { useRealtimeDashboardViewModel } from '@/modules/real-time/viewmodels/realtime-dashboard.view-model';
import { Bar, Column } from '@ant-design/plots';
import { Button, Card, Col, Row, Statistic, StatisticProps } from 'antd';
import React from 'react';
import CountUp from 'react-countup';
import '@/modules/real-time/scss/realtime-dashboard.scss';

export const RealtimeDashboard = () => {
  const { cr45Data, pickupCallData, filter, setFilter } = useRealtimeDashboardViewModel(30000);
  const formatter: StatisticProps['formatter'] = (value) => (
    <CountUp end={value as number} separator=',' />
  );
  const config = {
    data: pickupCallData.topUsers,
    xField: 'userName',
    yField: 'totalCalls',
    title: 'Top 10 Salemans theo số cuộc gọi nhấc máy',
    theme: 'academy',
    colorField: '#e31f26',
  };

  const barConfig = {
    data: cr45Data.topBusinessUnits,
    xField: 'businessUnitName',
    yField: 'cr45',
    theme: 'academy',
    colorField: '#e31f26',
    sort: {
      reverse: true,
    },
    title: 'Top BUs theo CR45',
  };

  return (
    <>
      <Row id={'top'} gutter={16}>
        <Col span={12}>
          <Row>
            <Col span={8}>
              <Card>
                <Statistic
                  title='Tổng cuộc gọi'
                  value={pickupCallData.totalPickUpCall}
                  formatter={formatter}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={5}>
            <Col span={6}>
              <Card>
                <Statistic
                  title='CR45'
                  value={cr45Data.overall}
                  formatter={(value) => {
                    return <CountUp end={value as number} suffix=' %' separator=',' />;
                  }}
                />
              </Card>
            </Col>
            <Col id='filter' span={18}>
              <Button
                onClick={() => {
                  setFilter('today');
                }}
                className={filter.timePeriod === 'today' ? 'timePeriodActive' : ''}
                size='large'
              >
                Hôm nay
              </Button>
              &nbsp;
              <Button
                className={filter.timePeriod === 'thisWeek' ? 'timePeriodActive' : ''}
                onClick={() => {
                  setFilter('thisWeek');
                }}
                type='default'
                size='large'
              >
                Tuần này
              </Button>
              &nbsp;
              <Button
                className={filter.timePeriod === 'thisMonth' ? 'timePeriodActive' : ''}
                onClick={() => {
                  setFilter('thisMonth');
                }}
                type='default'
                size='large'
              >
                Tháng này
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row id='chart-draw' gutter={16} className='mt-3'>
        <Col span={8}>
          <div style={{ background: '#fff' }}>
            <Column {...config} />
          </div>
        </Col>
        <Col span={16}>
          <Row gutter={5}>
            <Col span={8}>
              <div style={{ background: '#fff' }}>
                <Bar {...barConfig} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
