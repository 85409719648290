import { useBaseViewModel } from '@/common';
import { CallUserMetric, Cr45MetricEntity } from '@/domain/entities/real-time';
import { GetTotalPickupCallMetric } from '@/domain/use-cases/real-time/get-total-pickup-call-metric';
import { RealTimeRepository } from '@/modules/real-time/adapters/repositories';
import { useEffect, useRef, useState } from 'react';

interface PickCallChartState {
  topUsers: CallUserMetric[];
  totalPickUpCall: number;
}

export type TimePeriod = 'today' | 'thisWeek' | 'thisMonth';

export type RealTimeDashboardViewModelProps = {
  pickupCallData: PickCallChartState;
  filter: {
    timePeriod: TimePeriod;
  };
  cr45Data: Cr45MetricEntity;
  loading: boolean;
  error: Error | undefined;
  setFilter: (timePeriod: TimePeriod) => void;
};

export const useRealtimeDashboardViewModel = (
  interval = 30000,
): RealTimeDashboardViewModelProps => {
  let lastTimePeriod = localStorage.getItem('timePeriod') as TimePeriod;
  if (!lastTimePeriod) {
    lastTimePeriod = 'today';
  }
  const [pickupCallData, setPickupCallData] = useState<PickCallChartState>({
    topUsers: [],
    totalPickUpCall: 0,
  });

  const [cr45Data, setCr45Data] = useState<Cr45MetricEntity>({
    overall: 0,
    timePeriod: lastTimePeriod,
    topBusinessUnits: [],
  });

  const [timePeriod, setTimePeriod] = useState<{
    timePeriod: TimePeriod;
  }>({
    timePeriod: lastTimePeriod as TimePeriod,
  });

  const timePeriodRef = useRef(timePeriod);
  const setFilter = (timePeriod: TimePeriod) => {
    setTimePeriod({ timePeriod: timePeriod });
    localStorage.setItem('timePeriod', timePeriod);
  };

  const useCase = new GetTotalPickupCallMetric(new RealTimeRepository());
  const { loading, error, catchAction } = useBaseViewModel();

  const fetchRealtimeData = () => {
    console.log('Fetch realtime data');
    catchAction(async () => {
      const data = await useCase.run(timePeriodRef.current.timePeriod);
      setPickupCallData({
        topUsers: data.topUsers,
        totalPickUpCall: data.totalPickUpCall,
      });
      setCr45Data(data.cr45);
    });
  };
  let fetchInterval: NodeJS.Timeout;
  const registerInterval = () => {
    console.log(`Register interval ${interval}`);
    fetchInterval = setInterval(() => {
      fetchRealtimeData();
    }, interval);
  };

  useEffect(() => {
    timePeriodRef.current = timePeriod;
    fetchRealtimeData();
  }, [timePeriod]);

  useEffect(() => {
    fetchRealtimeData();
    registerInterval();
    return () => {
      clearInterval(fetchInterval);
    };
  }, [interval]);

  return {
    setFilter,
    filter: timePeriod,
    cr45Data,
    pickupCallData,
    loading,
    error,
  };
};
