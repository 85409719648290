import { RealTimeMetricEntity } from '@/domain/entities/real-time';
import { IRealTimeRepository } from '@/domain/ports/real-time/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { REAL_TIME_METRIC } from '@/modules/real-time/graphql';

interface IRealtimeMetricResponse {
  RealTimeMetric_Cr45: {
    timePeriod: string;
    overall: number;
    topBusinessUnits: {
      businessUnitId: string;
      businessUnitName: string;
      cr45: number;
    }[];
  };
  RealTimeMetric_TotalPickUpCall: {
    totalPickUpCall: number;
    topUsers: {
      userId: string;
      metricName: string;
      userName: string;
      totalCalls: string;
      lastUpdate: string;
    }[];
  };
}

export class RealTimeRepository implements IRealTimeRepository {
  async getRealTimeMetric(timePeriod: string): Promise<RealTimeMetricEntity> {
    const response = await HttpClientAdapter.query<IRealtimeMetricResponse>({
      query: REAL_TIME_METRIC,
      variables: {
        timePeriod: timePeriod ?? 'today',
      },
    });
    return {
      totalPickUpCall: response.RealTimeMetric_TotalPickUpCall.totalPickUpCall,
      topUsers: response.RealTimeMetric_TotalPickUpCall.topUsers,
      cr45: {
        timePeriod: response.RealTimeMetric_Cr45.timePeriod,
        overall: response.RealTimeMetric_Cr45.overall,
        topBusinessUnits: response.RealTimeMetric_Cr45.topBusinessUnits,
      },
    };
  }
}
