import { OperationVariables, QueryOptions } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { initApolloClient } from './apollo-client';
import { ActionNotAllowed } from './exceptions/ActionNotAllowed';

export interface MutateDataParams {
  mutation: DocumentNode;
  variables?: Record<string, unknown>;
  successMessage?: string;
}

export interface HttpClientAdapterParams {
  query: DocumentNode;
  variables?: OperationVariables;
  dataKey?: string;
}

export class HttpClientAdapter {
  static async query<T>(params: HttpClientAdapterParams & QueryOptions): Promise<T> {
    const apolloClient = await initApolloClient();
    const options = Object.assign({}, params);
    const response = await apolloClient.query({
      ...options,
      query: params.query,
      variables: params.variables,
    });
    if (response.errors && response.errors.length > 0) {
      for (const error of response.errors) {
        if (error.message === 'NOT_ALLOW_REPORT_VIEW') {
          throw new ActionNotAllowed();
        }
      }
    }
    return params.dataKey ? response.data[`${params.dataKey}`] : response.data;
  }

  static async mutate<T>({
    mutation,
    variables,
  }: {
    mutation: DocumentNode;
    variables?: OperationVariables;
  }): Promise<T> {
    const apolloClient = await initApolloClient();
    const response = await apolloClient.mutate<T>({
      mutation,
      variables,
    });
    if (response.errors && response.errors.length > 0) {
      for (const error of response.errors) {
        if (error.message === 'NOT_ALLOW_REPORT_VIEW') {
          throw new ActionNotAllowed();
        }
      }
    }
    const { data } = response;
    if (!data) {
      throw new Error('Not Found');
    } else {
      return data;
    }
  }
}
